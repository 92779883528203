.quotes-list-root {
    width: 100%;
    max-height: 90vh;
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation-name: fade-in;
    animation-duration: 1000ms;
    overflow-y: auto;
}
.quotes-list {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    /* gutter size offset */
    width: auto;

    padding-right: 1rem;
    animation-name: fade-in;
    animation-duration: 1000ms;
}

.quotes-list-col {
    padding-left: 1rem;
    /* gutter size */
    background-clip: padding-box;
}

.toast {
    position: fixed;
    bottom: 0;
    padding: 1rem 1.5rem;
    background-color: #800080;
    color: white;
    border-radius: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 5px 2px rgba(128, 0, 128, .25);
    opacity: 0;
    transition: 300ms;
}

.toast.shown {
    opacity: 1;
    transition: 300ms;
}

.paginate-button-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2rem;
    display: flex;
    gap: 1rem;
}


.paginate-button {
    cursor: pointer;
    background-color: #800080;
    padding: 1rem 1.75rem;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    box-shadow: 2px 2px 10px 2px #80008069;
    z-index: 2;
    transition: 300ms;
}

.paginate-button:hover {
    background-color: #900090;
}

@media screen and (max-width: 768px) {
    .quotes-list-root {
        margin-bottom: 4rem;
        /* max-height: 60vh; */
        
    }
    .paginate-button-container {
        position: fixed;
        bottom: 4rem;
        margin: 1rem;
    }

    .paginate-button {
        width: 2rem;
        height: 2rem;
        padding: 1rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.5rem;
    }
    .toast {
        bottom: 4rem;
    }
}