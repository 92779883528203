@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family:  'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: .35rem;
  -webkit-transition: 300ms;
  transition: 300ms;
}

::-webkit-scrollbar:hover {
  -webkit-transition: 300ms;
  transition: 300ms;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #8000802d;
  box-shadow: inset 0 0 6px #8000802d;
  -webkit-transition: 300ms;
  transition: 300ms;
}

::-webkit-scrollbar-thumb {
  background-color: #8000806d;
  border-radius: 1rem;
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-transition: 300ms;
  transition: 300ms;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #800080;
  border-radius: 1rem;
}

@media  screen and (max-width: 768px){
  ::-webkit-scrollbar {
    display: none;
  }
}