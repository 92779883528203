.category-root {
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    transition: 300ms;
    justify-content: center;
    box-sizing: border-box;
}

.category-list-container {
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    flex: 1;
    position: sticky;
    overflow-y: auto;
}

.category-holder {
    min-width: 45%;
    max-width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: .5rem;
    overflow: hidden;
    background-color: #FAFAFA;
    transition: 300ms;
    animation-name: fade-in;
    animation-duration: 1000ms;
    cursor: pointer;
    flex-grow: 1;
}

.category-image {
    height: 10rem;
    width: 10rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    object-fit: cover;
    border-radius: 0% 10% 35% 0% / 10% 10% 100% 10%;
    box-shadow: 2px 0 10px 5px rgba(0, 0, 0, .15);
}

.category-description-container {
    text-align:left;
    margin-left: 2rem;
}


.category-holder:hover {
    box-shadow: 4px 4px 10px 3px #80008043;
    background-color: #800080;
    color: white;
    letter-spacing: 1px;
}

.category-quotes {
    width: 100%;
    max-height: 90vh;
    position:sticky;
    flex: 1.5;
    border-left: 1px solid #80008022;
    background-color: #FAFAFA;
}

.category-description-container {
    margin-left: 1rem;
    padding-right: 1rem;
}

@media screen and (max-width: 2000px){
    .category-holder.single-column {
        max-width: 100%;
    }
}

@media screen and (max-width: 1000px){
    .category-root {
        flex-direction: column;
        align-items: center;
    }

    .category-list-container {
        width: 100%;
        margin: unset;
        padding: unset;
        padding-bottom: .5rem;
        gap: 1rem;
        align-items: flex-start;
    }

    .category-holder {
        min-width: 90%;
    }

    .horizontal-row {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .category-description-container {
        margin-left: 1rem;
        padding-right: 1rem;
    }

    .category-description-container h2 {
        font-size: 1.2rem;
    }

    .category-description-container p {
        font-size: .9rem;
        margin-right: .5rem;
    }

    .category-image {
        height: 7.5rem;
        width: 7.5rem;
        border-radius: 0% 10% 44% 0% / 10% 10% 87% 10%;
    }

    .category-quotes {
        margin-left: -1rem;
        border-left: unset;
        margin: auto;
        width: 100%;
        max-height: 70vh;
        position: relative;
    }
}