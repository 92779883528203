.App {
  text-align: center;
  /* background-color: #282c34; */
  min-height: 100vh;
  /* max-height: 100vh; */
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
    position: sticky;
    top: 0;
    left: 0;
}

.App-header {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
  padding-left: 16px;
  padding-right: 16px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
}

.App-nav {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}

.App-nav a {
  text-decoration: none;
  text-transform: uppercase;
  color: #AAA;
  padding: 1em;
  font-size: 1.05rem;
  font-weight: 400;
  transition: 500ms;
}

.App-nav a:hover {
  color: #444;
  transition: 500ms;
}

.App-nav a.active {
  border-bottom: 1px solid purple;
  color: purple;
  font-weight: 900;
  transition: 500ms;
  animation-name: nav-selected;
  animation-duration: 500ms;
}

@keyframes nav-selected {
  from {
    border-bottom-color: transparent;
  }

  to {
    border-bottom-color: #800080;
  }
}
h1 span {
  color: purple;
}

.app-body {
  min-height: 87vh;
  display: flex;
}

.vertical-side-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  writing-mode: vertical-lr;
  background-color: #fff;
  padding: .5rem;
  border-right: 1px solid #ccc;
  font-weight: bold;
  height: 87vh;
  position: sticky;
  top: 10vh;
  left: 0;
  z-index: 100;
}

.vertical-side-title {
  flex: 2;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
}

.vertical-side-bottom {
  color: #800080;
  letter-spacing: 0.15rem;
  margin-bottom: 1rem;
}

.social-links {
    display: flex;
    gap: 1rem;
    color: #ccc;
}
.social-links a {
  color: #999;
  font-size: 1.1rem;
  transition: 500ms;
}

.social-links a:hover {
  color: #800080;
}


@media screen and (max-width: 768px) {
  /* .app-body {
    flex-direction: column;
  } */

  .App-title{
    font-size: 1.5rem;
    margin-right: 0;
    padding-right: 0;
  }

  .vertical-side-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    writing-mode: horizontal-tb;
    background-color: #fff;
    padding: .5rem;
    border-top: 1px solid #ccc;
    font-weight: bold;
    width: 100%;
    height: fit-content;
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, .1)
  }

  .vertical-side-bottom {
    color: #800080;
    letter-spacing: 0.15rem;
    margin-right: 1rem;
  }

  .vertical-side-title {
    text-align: left;
  }

  .App-header {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    gap: 0%;
  }

  .App-nav {
    padding-left: 2.5rem;
    padding-right: 16px;
    margin-top: .25rem;
    min-width: 100%;
    scrollbar-width: none; /* The most elegant way for Firefox */
  }

  ::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #00000000;
  }

  .App-nav a {
    font-size: .95rem;
  }

  .social-links {
    margin-left: 2rem;
  }

  .social-links a {
    font-size: 1rem;
  }
}
