.author-list {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    animation-name: fade-in;
    animation-duration: 1000ms;
    flex: 1;
    max-height: 90vh;
    position: sticky;
    overflow-y: auto;
}

.author-root {
    display: flex;
    transition: 300ms;
    /* max-height: 90vh; */
}

.author-image {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-right: -3.5rem;
    object-fit: cover;
    z-index: 1;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: 300ms;
}

.author-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
    animation-name: fade-in;
    animation-duration: 1000ms;
}

.author-details-holder {
    width: 20ch;
    text-align: left;
    padding-left: 3.5rem;
    padding-right: 1rem;
    background-color: #FAFAFA;
    border-radius: 1rem;
    box-shadow: 0 0 5px 2px rgba(0,0,0, .1);
    cursor: pointer;
    transition: 300ms;
}

.author-details-holder.author-holder-hovered,
.author-image.author-holder-hovered {
   box-shadow: 0 0 5px 2px #80008043;
   background-color: #800080;
   color: white;
}

.author-quotes {
    flex: 2;
    transition: 300ms;
    border-left: 1px solid #80008022;
    background-color: #FAFAFA;
}

@media screen and (max-width: 1000px){
    /* .author-quotes {
        position: absolute;
        z-index: 2;
    } */

    .author-list{
        width: 100%;
        align-items: flex-start;
    }

    .author-list::-webkit-scrollbar {
        display: none;
    }

    .horizontal-row {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .author-root {
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
    }

    .author-holder {
        flex-direction: column;
        padding-left: unset;
    }

    .author-details-holder {
        width: 20ch;
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
    }

    .author-details-holder h2 {
        font-size: 1.2rem;
        margin-top: .5rem;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .author-details-holder h4 {
        font-size: .9rem;
        margin-top: .5rem;
        padding-top: 0px;
        margin-bottom: .5rem;
    }

    .author-image {
        width: 4rem;
        height: 4rem;
        margin-right: unset;
        margin-bottom: -3rem;
    }
    
}