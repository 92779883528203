.quote-card {
    border-radius: 1rem;
    border: 2px dotted rgb(150, 150, 150);
    max-width: 100%;
    /* width: 25ch; */
    font-size: 1.5em;
    padding: 2rem;
    line-height: 3rem;
    text-align: left;
    margin-bottom: 1rem;
    transition: 300ms;
    background-color: #fff;
}

.quote-card:hover {
    cursor: pointer;
    border-radius: 1rem;
    border: 2px solid rgba(128, 0, 128, .5);
    box-shadow: 0px 0px 5px 2px rgba(128, 0, 128, .25);
    /* padding: 4rem 2rem; */
}

.quote-card h6 {
    margin-top: 1rem;
    margin-left: -2.5rem;
    margin-bottom: 0;
    padding: 0.25rem 1rem;
    display: inline;
    background: linear-gradient(90deg, #ffffff91, #f1f1f1, transparent);
    border-radius: .5rem 0 0 0;
}

div.copy {
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #800080;
    padding: 0rem .75rem;
    border-radius: 0 0 .5rem .5rem;
    margin-top: -2.05rem;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .25);
    display: inline-block;
    transform: scaleY(0);
    transform-origin: top;
    transition: 300ms;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.75);
}

div.copy.hovered {
    transform: scaleY(1);
    transition: 300ms;
    transform-origin: top;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.75);
}

.quote-card img {
    width: 4rem;
    height: 4rem;
    margin-top: 0rem;
    margin-bottom: -.30rem;
    margin-left: .5rem;
    object-fit: cover;
    border-radius: 1rem .2rem .5rem .1rem;
}


@media screen and (max-width: 768px) {
    .quote-card {
        box-sizing: border-box;
        width: 100%;
        font-size: 1.25em;
        line-height: 2rem;
        padding: 1.5rem;
        margin-left: -.5rem;
    }
    div.copy {
        margin-top: -1.55rem;
    }
}